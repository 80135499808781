import { Component, signal, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { IContactMail, PublicSearchResult } from '@beathletics/api-interfaces';
import { AppFeatureService, AuthService } from '@beathletics/auth';
import { ApiServiceService } from '@beathletics/beathletics-data-state';
import { ContactFormComponent } from '@beathletics/beathletics-ui';
import { Subject, catchError, debounceTime, filter, map, of, switchMap, tap } from 'rxjs';
import { EmailManagementService } from './shared/email-management.service';
import { ThemeColorService } from './shared/theme-color.service';
import { BeathleticsUiModule } from '@beathletics/beathletics-ui';
import { RouterOutlet } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'beathletics-content-page',
  template: `
    <div class="container flex flex-col min-h-screen max-w-[1728px] snap-y snap-mandatory m-auto">
      <beathletics-main-header
        [theme_color]="$any(color$ | async)"
        [userProfile]="profile$ | async"
        [featuresAccess]="featuresAccess()"
        [searchAutocomplete]="searchAutocomplete()"
        (searchValue)="searchValue.next($event)"
        (contactForm)="openContactForm()"
        (logout)="logout($event)"
      ></beathletics-main-header>
      <div class="grow min-h-[1000px]">
        <router-outlet></router-outlet>
      </div>
      <beathletics-main-footer [theme_color]="color$ | async"></beathletics-main-footer>
    </div>
  `,
  standalone: true,
  imports: [BeathleticsUiModule, RouterOutlet, AsyncPipe],
})
export class ContentPageComponent {
  private authService = inject(AuthService);
  private apiService = inject(ApiServiceService);
  private themeColorService = inject(ThemeColorService);
  private appFeatureService = inject(AppFeatureService);
  private dialog = inject(MatDialog);
  private mailService = inject(EmailManagementService);

  color$ = this.themeColorService.theme_color$;
  profile$ = this.authService.getProfile();

  searchValue = new Subject<string>();
  searchAutocomplete = signal({
    athletes: [],
    competitions: [],
    loading: false,
    error: false,
  } as PublicSearchResult);

  subscription = this.searchValue
    .pipe(
      debounceTime(300),
      tap(() =>
        this.searchAutocomplete.update((value) => ({
          ...value,
          loading: true,
        })),
      ),
      filter((value) => !(value && value.length < 3)),
      switchMap((value) =>
        value
          ? this.apiService.publicSearchAthletesOrEvents(value).pipe(
              map((result) => ({ ...result, loading: false, error: false })),
              catchError(() => {
                return of({
                  athletes: [],
                  competitions: [],
                  loading: false,
                  error: true,
                });
              }),
            )
          : of({
              athletes: [],
              competitions: [],
              loading: false,
              error: false,
            } as PublicSearchResult),
      ),
      tap((value) => this.searchAutocomplete.set(value)),
    )
    .pipe(takeUntilDestroyed())
    .subscribe();

  featuresAccess = this.appFeatureService.featureAccess;

  openContactForm() {
    this.dialog
      .open(ContactFormComponent, {
        panelClass: ['responsive-dialog'],
        width: '512px',
      })
      .afterClosed()
      .subscribe((value?: IContactMail | string) => {
        if (value && typeof value !== 'string') {
          return this.mailService.sendContactEmail(value);
        }
      });
  }

  logout(value: boolean) {
    if (value === true) this.authService.logout();
  }
}
